"use client"
import React, { useState } from "react"
import "./style.css"
import { ContactUsModal } from "../Contact_Us_Modal"
import { toast } from "react-toastify"
import ReactGA from "react-ga4"

export function Footer(props) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    ReactGA.event({
      category: "footer_contact_us_button",
      action: "Click",
      label: window.location,
    })

    setShow(true)
  }

  const handleSubmit = (event) => {
    toast.info("🦄 Wow so easy!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  }

  return (
    <>
      <ContactUsModal
        show={show}
        handleClose={handleClose}
        heading="Are you interested? Send us a message and we will reply back to you within 24 hrs."
      />

      <div className="container footer">
        <div className="row">
          <div className="col-lg-3 footer-left">
            <div>
              <h3 className="footer-heading" onClick={handleSubmit}>
                Contact Us
              </h3>
              <h4 className="footer-subheading">Email</h4>
              <p>contact@wellm.co</p>
              <h4 className="footer-subheading">Phone</h4>
              <p>+91-7289899859</p>
              <h4 className="footer-subheading">Address</h4>
              <p>
                A-184, The Pinnacle, DLF Phase 5
                <br />
                Sector 43 - 122009
              </p>
            </div>
          </div>
          <div className="col-lg-6 footer-center">
            <img
              src="/img/wellm-logo.png"
              style={{ width: "8", height: "3.5rem", marginBottom: "16px" }}
              className="d-inline-block align-top logo"
              alt="wellm logo"
            />
            <p>
              Nurturing your emotional and mental well-being to pave the way for
              holistic wellness
            </p>
            <div className="socials">
              <a
                href="https://www.facebook.com/WellMofficial"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_facebook_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                <span className="social-icon">
                  <img src="/img/personal-wellness/facebook.svg" alt="facebook" />
                </span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCKbrdIwenV5n32GileurvrA"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_youtube_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                <span className="social-icon">
                  <img src="/img/personal-wellness/youtube.svg" alt="youtube" />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/wellm/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_linkedin_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                <span className="social-icon">
                  <img src="/img/personal-wellness/linkedln.svg" alt="linkedln" />
                </span>
              </a>
              <a
                href="https://www.instagram.com/wellmofficial/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_instagram_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                <span className="social-icon">
                  <img src="/img/personal-wellness/instagram.svg" alt="instagram" />
                </span>
              </a>
              <a
                href="https://twitter.com/TeamWellM"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_twitter_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                <span
                  className="social-icon"
                  style={{
                    borderRadius: "0",
                    position: "relative",
                    right: "1%",
                    padding: "0",
                    margin: "0",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src="/img/personal-wellness/twitter.svg"
                    alt="twitter"
                    style={{ width: "9vh" }}
                  />
                </span>
              </a>
            </div>
            <div className="certificates">
              <img
                loading="lazy"
                src="/img/footer/ISO.png"
                className="iso"
                style={{ width: "10%" }}
                alt=""
                onClick={() => {
                  ReactGA.event({
                    category: "footer_iso_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              />
              <a href="/hipa-policy" target="_blank">
                <img
                  loading="lazy"
                  className="hipaa"
                  src="/img/footer/hipa.png"
                  alt=""
                  onClick={() => {
                    ReactGA.event({
                      category: "footer_hipaa_link",
                      action: "Click",
                      label: window.location,
                    })
                  }}
                />
              </a>
            </div>
            <p style={{ fontSize: "14px" }}>
              Your personal data is strictly protected under data privacy policy.
            </p>
          </div>
          <div className="col-lg-3 footer-right">
            <h3 className="footer-right-heading">Legal</h3>
            <h4 className="footer-right-subheading">
              <a
                href="/terms-condition"
                target="_blank"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_terms_and_conditions_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                Terms & Condition
              </a>
            </h4>
            <h4 className="footer-right-subheading">
              <a
                href="/privacy-policy"
                target="_blank"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_privacy_policy_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                Privacy Policy
              </a>
            </h4>
            <h4 className="footer-right-subheading">
              <a
                href="/refund-policy"
                target="_blank"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_refund_policy_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                Refund Policy
              </a>
            </h4>
            <h4 className="footer-right-subheading">
              <a
                href="/disclaimer"
                target="_blank"
                onClick={() => {
                  ReactGA.event({
                    category: "footer_disclaimer_link",
                    action: "Click",
                    label: window.location,
                  })
                }}
              >
                Disclaimer
              </a>
            </h4>

            <h4 className="footer-right-subheading" onClick={handleShow}>
              Contact Us
            </h4>
          </div>
        </div>
        <p className="notice">
          At WellM, our primary focus is on promoting mental well-being and providing
          support to our users. However, it's essential to clarify that we do not
          have the capacity to address medical or psychological emergencies. This
          includes situations where an individual may be experiencing thoughts of
          self-harm, suicidal ideation, or exhibiting severe clinical disorders, such
          as schizophrenia and other psychotic conditions.
          <br />
          <br />
          In such critical circumstances, the most appropriate action is to seek
          immediate in-person medical intervention. We strongly urge you to visit the
          nearest hospital or emergency room, where you can connect with qualified
          professionals like psychiatrists, social workers, counselors, or therapists
          who can offer the necessary help and care. It's also beneficial to involve
          a close family member or friend who can provide valuable support during
          this time.
          <br />
          <br />
          You can also reach out to a suicide hotline in your country of residence:
          <a
            href="http://www.healthcollective.in/contact/helplines"
            target="_blank"
            style={{ marginLeft: "4px" }}
            rel="noreferrer"
          >
            http://www.healthcollective.in/contact/helplines
          </a>
        </p>
        <div className="copyright notice">
          <p>©Wellness Mandala. All rights reserved.</p>
        </div>
      </div>
    </>
  )
}
