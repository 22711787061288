import React, { useState, useEffect } from "react"
import "./style.css"
import { FaCalendarAlt } from "react-icons/fa"
import Popup from "../popup"
import CountdownTimer from "./components/timer/timer"
import PricingSection from "./components/price"
import RegistrationForm from "./components/form"

const WorkshopDetails = ({ workshopDetailsModel }) => {
  const [isPopupOpen, setPopupOpen] = useState(false)

  const { uid, banner, title, slug, pageData, faq, facilitator } =
    workshopDetailsModel

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupOpen(true)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="workshop-detail-page">
      {/* Banner Section */}
      <div className="workshop-banner">
        <img src={workshopDetailsModel.banner} alt="Workshop Banner" />
      </div>

      {/* Title and Registration */}
      {/* <div className="flex-container"> */}
      {/* </div> */}
      <h1>The Power of Gratitude: Transforming the Way You Talk to Yourself</h1>
      <CountdownTimer
        eventDateTime={new Date("2024-11-28T17:00:00")}
        setPopupOpen={setPopupOpen}
      />
      <RegistrationForm />
      {/* {/* <h1>The Power of Gratitude: Transforming the Way You Talk to Yourself</h1> */}
      {/* <div className="flex-justified-container">
        <div className="event-date">
          <FaCalendarAlt className="calendar-icon" />
          <span>28 Nov 2024 - 05:00 - 06:00 PM</span>
        </div>
        <div className="event-price">
          <span className="price-strikethrough">₹199</span>{" "}
          <span>
            <strong>Free</strong>
          </span>
        </div>
      </div> */}

      {/* Workshop Description */}
      <div className="workshop-section">
        <p>
          Do you often find yourself stuck in a loop of self-criticism or doubting
          your abilities? It’s time to break free and discover how gratitude can
          transform your inner dialogue, boost your confidence, and nurture
          self-compassion.
        </p>
      </div>

      {/* Why Gratitude */}
      <div className="workshop-section">
        <img
          src="https://images.unsplash.com/photo-1528938102132-4a9276b8e320?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          className="image-class"
        />
        <h2>Why Gratitude?</h2>
        <p>
          Gratitude is more than just thanking others—it’s a powerful tool to enhance
          emotional health and reframe the way you see yourself. By practising
          self-gratitude, you can:
        </p>
        <ul>
          <li>Replace self-doubt with self-confidence</li>
          <li>
            Build stronger relationships through compassion for yourself and others
          </li>
          <li>Reduce stress and foster positivity</li>
        </ul>
      </div>

      {/* What You'll Learn */}
      <div className="workshop-section">
        <img
          src="https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          className="image-class"
        />
        <h2>What You’ll Learn in This Workshop</h2>
        <ul>
          <li>
            <strong>The science behind gratitude:</strong> Why it’s a game-changer
            for emotional well-being
          </li>
          <li>
            <strong>The impact of self-talk:</strong> How negative self-talk affects
            your confidence and relationships
          </li>
          <li>
            <strong>Practical techniques:</strong> Tools to transform your inner
            critic into a source of encouragement and positivity
          </li>
        </ul>
      </div>

      {/* Workshop Overview */}
      <div className="workshop-section">
        <h2>Overview of the Workshop</h2>
        <ul>
          <li>What is gratitude, and why is it important?</li>
          <li>
            Understanding the inner critic: Why it exists and how it impacts us
          </li>
          <li>
            Reframing your inner voice: Tools and activities to shift how we talk to
            ourselves
          </li>
          <li>
            Reflection and self-compassion: Turning insights into daily practices
          </li>
        </ul>
      </div>

      {/* Facilitator Section */}
      <div className="workshop-section facilitator-section">
        <h2>About the Facilitator</h2>
        <div className="facilitator-info">
          <img src="/img/facilitator/viveka_150.png" alt="Viveka Mansata" />
          <div>
            <p>
              <strong>Viveka Mansata</strong>
            </p>
            <p>
              Bachelor’s degree in Psychology from the University of Delhi and a
              Master’s in Psychosocial Clinical Studies from Ambedkar University
              Delhi.
            </p>
            <p>Over 2 years of experience in psychosocial clinical practice.</p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="workshop-cta">
        {/* Only in mobile */}
        <div className="mobile-bar">
          <div className="event-date">
            <FaCalendarAlt className="calendar-icon" />
            <span>28 Nov 2024 - 05:00 PM</span>
          </div>
        </div>
        <button className="register-button" onClick={() => setPopupOpen(true)}>
          Enroll Now
        </button>
        <Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} />
      </div>
    </div>
  )
}

export default WorkshopDetails
