import React from "react"
import { Link, useLocation } from "react-router-dom"
import "./style.css"

const Breadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname.split("/").filter((x) => x)

  return (
    <nav className="breadcrumbs">
      <Link to="/">Home</Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`
        const breadcrumbText = value.charAt(0).toUpperCase() + value.slice(1)
        return (
          <span key={to}>
            <span className="breadcrumb-separator"> / </span>
            <Link to={to}>{breadcrumbText}</Link>
          </span>
        )
      })}
    </nav>
  )
}

export default Breadcrumbs
