import React from "react"
import { useNavigate } from "react-router-dom"
import "./style.css"

const WorkshopCard = ({ workShopModel }) => {
  const { uid, banner, title, expert, slug, dateOfWorkshop, totalRegistered } =
    workShopModel
  const navigate = useNavigate()

  function navigateToWorkshopPage() {
    navigate(`/workshops/${slug}`)
  }

  return (
    <div className="main-container" onClick={navigateToWorkshopPage}>
      {/* <div className="badge date-badge">{dateOfWorkshop}</div> */}
      {/* <div className="badge registration-badge">{totalRegistered} Registered</div> */}
      <img src={banner} alt={title} />
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{expert}</p>
        <button className="card-button">Book Now</button>
      </div>
    </div>
  )
}

export default WorkshopCard
