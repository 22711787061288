import React, {useState} from 'react'
import Button from "react-bootstrap/Button"
import "./style.css"
import FilterRadio from './filter-radio'

const FilterComponent = ({updateParentState}) => {
    const defaultFilters = {
        'workshop-type':'all',
        'workshop-status':'all',
    }
    const workShopTypeOptions = [
        { value: 'all', label: 'All' },
        { value: 'free', label: 'Free' },
        { value: 'paid', label: 'Paid' },
    ];
    const workShopStatusOptions = [
        { value: 'all', label: 'All' },
        { value: 'past', label: 'Past' },
        { value: 'upcoming', label: 'Upcoming' },
    ];
    const [selectedFilters, setSelectedFilters] = useState(defaultFilters)

    function changeWorkshopType(event) {
        const newFilters = {
            ...selectedFilters,
            'workshop-type': event.target.value,
        };
        setSelectedFilters(newFilters);
        updateParentState(newFilters); // Use updated filters
    }

    function changeWorkshopStatus(event) {
        const newFilters = {
            ...selectedFilters,
            'workshop-status': event.target.value,
        };
        setSelectedFilters(newFilters);
        updateParentState(newFilters); // Use updated filters
    }

    function clearAllFilters() {
        setSelectedFilters(defaultFilters);
        updateParentState(defaultFilters); // Reset filters to default
    }

    return (
        <div className='flex-spaceEvenly-col'>
            <div className='flex-spaceEvenly-row'>
                <h3>Filters</h3>
                <div className='gap-row'/>
                <Button 
                    className='clear-button' 
                    style={{ color: "white" }} 
                    variant="success"
                    disabled={JSON.stringify(selectedFilters) === JSON.stringify(defaultFilters)}
                    onClick={clearAllFilters}
                >
                    Clear
                </Button>
            </div>
            <div className='gap-col' />
            <FilterRadio 
            id={'workshop-type'} 
            title={'Workshop Type'} 
            onChange={changeWorkshopType} 
            options={workShopTypeOptions} 
            selectedValue={selectedFilters['workshop-type']}
            />
            <div className='gap-col' />
            <FilterRadio
            id={'workshop-status'} 
            title={'Workshop Status'} 
            onChange={changeWorkshopStatus} 
            options={workShopStatusOptions} 
            selectedValue={selectedFilters['workshop-status']}
            />
        </div>
    )
}

export default FilterComponent