import React from "react"
import "./style.css"

const PricingSection = () => {
  return (
    // <div className="pricing-section">
    //   <h2>Workshop Pricing</h2>
    <div>
      {/* <h1>The Power of Gratitude: Transforming the Way You Talk to Yourself</h1> */}
      <div className="pricing-container">
        <div className="original-price">
          <span className="strikethrough">₹199</span>
        </div>
        <div className="current-price">
          <span className="highlight">Free</span>
        </div>
      </div>
    </div>
    //   <p className="limited-time">Limited Time Offer! Register Now for Free.</p>
    // </div>
  )
}

export default PricingSection
