import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import emailjs from "emailjs-com"
import "./style.css" // Add the new CSS file

const RegistrationForm = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await emailjs.send(
        "wellm_workshop_mailer",
        "workshop_lead_email_temp",
        formData,
        "AnXWM3GejQiaDX1g-"
      )
      setFormData({ name: "", email: "", phone: "" })
      navigate("/thank-you-page")
    } catch (error) {
      console.error("EmailJS Error:", error)
      alert("Failed to send email. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="main-container-form">
      <h2 className="form-title">
        <strong>Enroll in This Workshop</strong>
      </h2>
      <form onSubmit={handleSubmit} className="registration-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Submitting..." : "Enroll Now"}
        </button>
        {loading && (
          <div className="loader-container" aria-live="polite">
            <div className="circular-loader"></div>
          </div>
        )}
      </form>
    </div>
  )
}

export default RegistrationForm
