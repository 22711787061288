import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import "./style.css"
import Breadcrumbs from "../../../components/Breadcrumbs"
import WorkshopDetails from "./components/workshop-details"

const dummyData = {
  uid: "584C7C59",
  banner: "/img/workshops/power_of_gratitude.png",
  title: "MAKING PEACE WITH SLEEP",
  slug: "make-peace-with-sleep",
  pageData: `
    <p><span style="font-weight: 400;">You&rsquo;ve likely heard that sleep is critical for health, but have you thought about how it affects your performance?</span></p>
<p><span style="font-weight: 400;">Tiredness can take a toll on productivity, focus, and overall morale. But what if the solution to your performance problems is as simple as getting a good night's sleep?</span></p>
<p><strong>The Science of Sleep and Success:</strong></p>
<p><span style="font-weight: 400;">Sleep is more than just a way to recharge. It's essential for cognitive performance, helping you:</span></p>
<ul>
<li style="font-weight: 400;"><strong>Think more clearly:</strong><span style="font-weight: 400;"> Improved focus and decision-making</span></li>
<li style="font-weight: 400;"><strong>Be more creative:</strong><span style="font-weight: 400;"> Enhanced problem-solving and innovative thinking</span></li>
<li style="font-weight: 400;"><strong>Have more energy:</strong><span style="font-weight: 400;"> Increased stamina and motivation</span></li>
</ul>
<p><strong>Lack of sleep can lead to:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Reduced focus</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Poor decision-making</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Decreased overall productivity</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Increased stress and burnout</span></li>
</ul>
<p><strong>Introducing Our Workshop: Making Peace with Sleep</strong></p>
<p><span style="font-weight: 400;">In our workshop, you will learn:</span></p>
<ul>
<li style="font-weight: 400;"><strong>The science behind sleep:</strong><span style="font-weight: 400;"> Understand why sleep is crucial for cognitive performance.</span></li>
<li style="font-weight: 400;"><strong>Practical sleep tips:</strong><span style="font-weight: 400;"> Discover actionable strategies to improve sleep quality, even in high-stress environments.</span></li>
<li style="font-weight: 400;"><strong>The benefits of better sleep:</strong><span style="font-weight: 400;"> See how improved sleep can directly contribute to your focus, creativity, and energy.</span></li>
</ul>
<h2><strong>Overview of the workshop:</strong></h2>
<ul>
<li style="font-weight: 400;">
<h2><span style="font-weight: 400;">What happens when we sleep?</span></h2>
</li>
<li style="font-weight: 400;">
<h2><span style="font-weight: 400;">Why am I not sleeping well?</span></h2>
</li>
<li style="font-weight: 400;">
<h2><span style="font-weight: 400;">Creating a sleep schedule</span></h2>
</li>
<li style="font-weight: 400;">
<h2><span style="font-weight: 400;">Dealing with a racing mind</span></h2>
</li>
<li style="font-weight: 400;">
<h2><span style="font-weight: 400;">Soothing techniques</span></h2>
</li>
</ul>
  `,
  faq: [
    {
      title: "What will I learn in the 'Making Peace with Sleep' workshop?",
      desc: "In this workshop, you'll explore the science behind sleep, learn practical tips to improve sleep quality, and discover how restful sleep can enhance focus, creativity, and productivity.",
    },
    {
      title: "Who is the facilitator for this workshop?",
      desc: "The workshop is led by Sameera Sachdev, a licensed clinical psychologist and certified mindfulness facilitator specializing in trauma, CBT/DBT practices, and sleep wellness.",
    },
    {
      title: "How long is the workshop?",
      desc: "The duration of the workshop may vary, but it typically lasts around 1.5 to 2 hours, including interactive discussions and Q&A sessions.",
    },
    {
      title: "Is the workshop suitable for people with sleep disorders?",
      desc: "Yes, this workshop is designed for anyone looking to improve their sleep habits. While it may offer valuable insights for those with sleep disorders, please consult a healthcare provider for personalized guidance.",
    },
    {
      title: "How can I register for the workshop?",
      desc: "You can easily register by clicking the “Book Now” button on this page, which will guide you through the registration process.",
    },
  ],
  facilitator: [
    {
      image: "https://www.wellm.co/img/sameera.jpg",
      name: "Sameera Sachdev",
      designation: "Clinical Psychologist Well M",
      about:
        "(RCI Licensed Clinical Psychologist, Brown University Certified Mindfulness Facilitator, Grief and Trauma Specialist from Institute of Loss, Portland, EAP Specialist for Mindfulness-Based Practices- Stress, Sleep and Pain, Certified CBT & DBT Practitioner)",
    },
  ],
  contactEmail: "contact@wellm.co",
  contactPhone: "+91-7289899859",
  viewCount: 0,
  registrationCount: 999,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  tags: ["sleep", "wellness", "mindfulness", "productivity"],
  duration: "2 hours",
  location: "Online",
  language: "English",
  publishedStatus: "draft",
  dueDate: "2024-12-08",
  isDone: false,
  price: 1999,
  discount: 0.2,
  currency: "INR",
  callbackURL: "https://example.com/post-purchase",
}

const WorkshopPage = () => {
  const [workshopModel, setWorkshopModel] = useState({})
  // const location = useLocation()

  // useEffect(() => {
  //   const uid = getTheParamsFromSlug()
  //   console.log("UID:", uid)
  //   if (uid !== null && uid !== undefined) {
  //     loadData(uid)
  //   }
  //   //fetch json data
  //   return () => {
  //     //nullify the state
  //     setWorkshopModel({})
  //   }
  // }, [location])

  // async function loadData(uid) {
  //   setWorkshopModel(dummyData)
  // }

  // function getTheParamsFromSlug() {
  //   const searchParams = new URLSearchParams(location.search)
  //   return searchParams.get("uid")
  // }

  return (
    <div className="pss">
      <section>
        <Breadcrumbs />
        <WorkshopDetails workshopDetailsModel={dummyData} />
      </section>
      {/* <BookingSidebar /> */}
    </div>
  )
}

export default WorkshopPage
