import React, { useState } from "react"
import "./style.css"
import FilterComponent from "./components/filter"
import WorkshopCard from "./components/workshop-card"

const dataSchema = [
  {
    uid: "584C7C59",
    banner: "/img/workshops/power_of_gratitude.png",
    title: "Power of gratitude",
    slug: "power-of-gratitude",
    expert:
      "Do you often find yourself stuck in a loop of self-criticism or doubting your abilities? It’s time to break free and discover how gratitude can transform your inner dialogue, boost your confidence, and nurture self-compassion.",
    dateOfWorkshop: "2024-11-28",
    totalRegistered: Math.random(0, 100).toString(),
    workshopType: "Free",
  },
]

const WorkShopMainPage = () => {
  const [workShopList, setWorkShopList] = useState([...dataSchema])

  async function fetchWorkShops(type, status) {
    try {
      const response = await fetch(
        `https://my-cloud-api/get/workshops?type=${type}&status=${status}`
      )
      const workshops = await response.json()
      setWorkShopList(workshops)
    } catch (error) {
      console.error("Failed to fetch workshops:", error)
    }
  }

  function updateWorkshopList(filterData) {
    const type = filterData["workshop-type"]
    const status = filterData["workshop-status"]
    const currentDate = new Date()

    const updatedList = dataSchema.filter((e) => {
      const workshopDate = new Date(e.dateOfWorkshop)

      const typeMatch = type === "all" || e.workshopType.toLowerCase() === type

      let statusMatch = true
      if (status === "past") statusMatch = workshopDate < currentDate
      if (status === "upcoming") statusMatch = workshopDate >= currentDate

      return typeMatch && statusMatch
    })

    setWorkShopList(updatedList)
  }

  return (
    <div className="primarySection">
      <aside className="side-bar">
        <FilterComponent updateParentState={updateWorkshopList} />
      </aside>
      <div className="main-content">
        <h1>Workshops - {workShopList.length}</h1>
        <div className="grid-main">
          {workShopList.map((e, i) => (
            // Abhishek-TODO: Remove i and replace key with e.uid
            <WorkshopCard key={`${i}-workshopCard`} workShopModel={e} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WorkShopMainPage
