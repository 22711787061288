import React, { useState } from "react"
import emailjs from "emailjs-com"
import "./style.css"
import { FaCircle, FaTimes } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

const Popup = ({ isOpen, onClose }) => {
  // const router = useRouter
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  })

  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await emailjs
      .send(
        "wellm_workshop_mailer", // Replace with your EmailJS Service ID
        "workshop_lead_email_temp", // Replace with your EmailJS Template ID
        formData,
        "AnXWM3GejQiaDX1g-" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          // alert("Your enrollment has been submitted!")
          setFormData({ name: "", email: "", phone: "" })
          setLoading(false)
          navigate("/thank-you-page")
          onClose()
        },
        (error) => {
          console.error("EmailJS Error:", error)
          alert("Failed to send email. Please try again later.")
        }
      )
  }

  if (!isOpen) return null

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Enroll in This Workshop</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Enroll Now
            {loading && (
              <div className="loader-container">
                <div className="circular-loader"></div>
              </div>
            )}
          </button>
        </form>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
    </div>
  )
}

export default Popup
