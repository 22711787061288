import React from 'react'
import { FormControlLabel, Radio, RadioGroup,FormControl, FormLabel } from '@mui/material'

const FilterRadio = ({ title, options, selectedValue, onChange, id }) => {
    return (
        <FormControl>
            <FormLabel id={id} className='filter-heading'>{title}</FormLabel>
            <RadioGroup
                aria-labelledby={`${id}-aria-label`}
                name={`${title}-type-radio-group`}
                value={selectedValue}
                onChange={onChange}
            >
                {
                    options.map(option=>(
                        <FormControlLabel key={option.value} value={option.value} label={option.label} control={<Radio />} />
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}

export default FilterRadio