import React, { useEffect, useState } from "react"
import "./style.css"
import { FaCalendar } from "react-icons/fa"
import PricingSection from "../price"

const CountdownTimer = ({ eventDateTime, setPopupOpen }) => {
  const [timeLeft, setTimeLeft] = useState({})
  const [registrants, setRegistrants] = useState(50)

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(eventDateTime) - new Date()
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        })
      } else {
        setTimeLeft({})
      }
    }

    const timer = setInterval(calculateTimeLeft, 1000)
    return () => clearInterval(timer)
  }, [eventDateTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setRegistrants((prev) => prev + Math.floor(Math.random() * 5 + 1))
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const formatTime = (time) => (time ? String(time).padStart(2, "0") : "00")

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
    return date.toLocaleDateString("en-US", options)
  }

  return (
    <div className="countdown-timer">
      <div className="flex-box">
        <h2 className="workshop-title">Book your spot now</h2>
        <PricingSection />
      </div>
      <p className="event-date-time">
        <FaCalendar className="padding-right" />
        {formatDate(eventDateTime)}
      </p>

      <div className="timer">
        <div className="time-box">
          <span>{formatTime(timeLeft.days)}</span>
          <p>Days</p>
        </div>
        <div className="time-box">
          <span>{formatTime(timeLeft.hours)}</span>
          <p>Hours</p>
        </div>
        <div className="time-box">
          <span>{formatTime(timeLeft.minutes)}</span>
          <p>Minutes</p>
        </div>
        <div className="time-box">
          <span>{formatTime(timeLeft.seconds)}</span>
          <p>Seconds</p>
        </div>
      </div>

      <button className="cta-button" onClick={() => setPopupOpen(true)}>
        Enroll Now
      </button>

      <div className="registrants-info">
        <p>+{registrants} registered for this workshop</p>
      </div>
    </div>
  )
}

export default CountdownTimer
