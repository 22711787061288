import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import NavBar from "./components/Navbar/Navbar"
import PersonalWellness from "./pages/personalWellness"

import { Footer } from "./components/Footer/Footer"
import HomePage from "./pages/homepage"

import { Routes, Route } from "react-router-dom"
import CorporateWellness from "./pages/corporateWellness"
import { AboutUsPage } from "./pages/aboutUsPage"
import StudentWellness from "./pages/studentWellness"
import WorkShopMainPage from "./pages/workshops/index.js"
import Partnerships from "./pages/partnerships"
import Disclaimer from "./pages/disclaimer"
import Hipaa from "./pages/hipa"
import Termsandcondition from "./pages/terms-conditions"
import Privacypolicy from "./pages/privacy-policy"
import RefundPolicy from "./pages/refund-policy"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import React, { useEffect } from "react"
import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom"
import WorkshopPage from "./pages/workshops/page/index.js"
import ThankYouPage from "./pages/thank-you-page/index.js"

function App() {
  const location = useLocation()
  useEffect(() => {
    ReactGA.initialize("G-N2TPVEF9ZW")
    ReactGA.send({ hitType: "pageview", page: window.location.pathName })
  }, [location])
  return (
    <>
      <NavBar />
      <div className="bodyView">
        <Routes>
          <Route exact path="/" element={<HomePage />}></Route>

          <Route
            exact
            path="/personal-wellness"
            element={<PersonalWellness />}
          ></Route>
          <Route
            exact
            path="/corporate-wellness"
            element={<CorporateWellness />}
          ></Route>
          <Route exact path="/about-us" element={<AboutUsPage />}></Route>
          <Route
            exact
            path="/student-wellness"
            element={<StudentWellness />}
          ></Route>
          <Route exact path="/partnerships" element={<Partnerships />}></Route>
          <Route exact path="/disclaimer" element={<Disclaimer />}></Route>
          <Route exact path="/hipa-policy" element={<Hipaa />}></Route>
          <Route
            exact
            path="/terms-condition"
            element={<Termsandcondition />}
          ></Route>
          <Route exact path="/privacy-policy" element={<Privacypolicy />}></Route>
          <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
          <Route exact path="/workshops" element={<WorkShopMainPage />} />
          <Route path="/workshops/*" element={<WorkshopPage />} />
          <Route path="/thank-you-page" element={<ThankYouPage />} />
          {/* <Route path="/workshops/" element={<WorkshopPage />} /> */}

          {/* <Route exact path='/contact' element={< Contact />}></Route>  */}
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </>
  )
}

export default App
