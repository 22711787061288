import React from "react"
import "./style.css"

const ThankYouPage = () => {
  return (
    <div className="thank-you-page">
      <div className="thank-you-container">
        <h1>Thank You for Registering!</h1>
        <p>
          We're thrilled to have you join us for this workshop. To stay informed and
          get updates, please join our WhatsApp group.
        </p>
        <a
          href="https://chat.whatsapp.com/LpWuSXyi7rP4zPthdpMGTv"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          Join WhatsApp Group
        </a>
      </div>
    </div>
  )
}

export default ThankYouPage
